import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Link,
  CircularProgress,
  DialogContent,
  Dialog,
  IconButton,
  Icon,
  DialogTitle,
  InputAdornment,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ReCAPTCHA from 'react-google-recaptcha';

import { translatePublicError } from '../../../utils/translateError';
import { PhoneNumberInput } from '../../../app/components/Common/PhoneNumberInput';

export default function SignUp() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState(null);
  const [payload, setPayload] = useState({ channel: '', email: '', phone: '', name: '' });
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeOpen, setVerificationCodeOpen] = useState(false);
  const recaptchaRef = useRef();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenInput = params.get('token');
    setToken(tokenInput);

    const payloadData = JSON.parse(atob(tokenInput.split('.')[1]));
    setPayload(payloadData);
    setEmail(payloadData.email || '');
    setPhone(payloadData.phone || '');
  }, [location.search]);

  const [
    sendEmailVerificationCode,
    { loading: sendingEmailVerificationCode, error: emailVerificationCodeError },
  ] = useMutation(
    gql`
      mutation SendEmailVerificationCode(
        $businessId: ID!
        $email: String!
        $recaptchaResponse: String!
      ) {
        sendEmailVerificationCode(
          businessId: $businessId
          email: $email
          recaptchaResponse: $recaptchaResponse
        )
      }
    `,
    {
      update: (cache, res) => {
        if (res.data && res.data.sendEmailVerificationCode) {
          setVerificationCode('');
          setVerificationCodeOpen(true);
        }
      },
    }
  );

  const [
    sendPhoneVerificationCode,
    { loading: sendingPhoneVerificationCode, error: phoneVerificationCodeError },
  ] = useMutation(
    gql`
      mutation SendPhoneVerificationCode(
        $businessId: ID!
        $phone: String!
        $recaptchaResponse: String!
      ) {
        sendPhoneVerificationCode(
          businessId: $businessId
          phone: $phone
          recaptchaResponse: $recaptchaResponse
        )
      }
    `,
    {
      update: (cache, res) => {
        if (res.data && res.data.sendPhoneVerificationCode) {
          setVerificationCode('');
          setVerificationCodeOpen(true);
        }
      },
    }
  );

  const [register, { loading, error, data, client }] = useMutation(
    gql`
      mutation RegisterCustomer(
        $invitationToken: String!
        $input: UserInput!
        $verificationCode: String!
      ) {
        registerCustomer(
          invitationToken: $invitationToken
          input: $input
          verificationCode: $verificationCode
        )
      }
    `
  );

  useEffect(() => {
    if (data && data.registerCustomer) {
      window.localStorage.setItem('authToken', data.registerCustomer);
      client.resetStore();
      navigate('/');
    }
  }, [data, navigate, client]);

  useEffect(() => {
    const verificationCodeError = emailVerificationCodeError || phoneVerificationCodeError;
    if (verificationCodeError) {
      enqueueSnackbar(verificationCodeError.message, { variant: 'error' });
    }
  }, [enqueueSnackbar, emailVerificationCodeError, phoneVerificationCodeError]);

  return (
    <>
      <Grid container sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${process.env.SIGNUP_BACKGROUND})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '100vh',
            }}
          >
            <Box component="img" src={process.env.SIGNUP_LOGO} sx={{ width: 220, height: 220 }} />
            <Typography component="h1" variant="h5">
              {payload.name
                ? `${payload.name.slice(0, 1).toUpperCase()}${payload.name
                    .split(' ')[0]
                    .slice(1)
                    .toLowerCase()}, ¡te damos la bienvenida!`
                : '¡Únete!'}
            </Typography>

            {error && (
              <Typography variant="body2" color="error" mt={4}>
                {translatePublicError(error.message)}
              </Typography>
            )}

            <Box sx={{ mt: 1 }}>
              {!payload.name && (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Nombre y apellido"
                  autoFocus
                  value={name || ''}
                  onChangeCapture={(e) => setName(e.target.value)}
                />
              )}

              {payload.channel === 'phone' && (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Correo electrónico"
                  value={email || ''}
                  onChangeCapture={(e) => setEmail(e.target.value)}
                />
              )}

              {payload.channel === 'email' && (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Teléfono"
                  value={phone || ''}
                  onChange={(e) => setPhone(e.target.value)}
                  InputProps={{
                    inputComponent: PhoneNumberInput,
                    startAdornment: <InputAdornment position="start">+52</InputAdornment>,
                  }}
                />
              )}

              <TextField
                margin="normal"
                required
                fullWidth
                label="Nueva contraseña"
                type="password"
                autoComplete="new-password"
                value={password || ''}
                onChangeCapture={(e) => setPassword(e.target.value)}
              />

              <Box sx={{ mb: 2, textAlign: 'center' }}>
                <Typography variant="caption" color="textSecondary">
                  {'Al registrarte aceptas los '}
                  <Link href={process.env.LEGAL} target="_blank">
                    términos de servicio
                  </Link>
                  .
                </Typography>
              </Box>

              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.RECAPTCHA_SITE_KEY}
              />

              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={async () => {
                  const recaptchaResponse = await recaptchaRef.current.executeAsync();
                  if (recaptchaResponse) {
                    if (payload.channel === 'email') {
                      sendPhoneVerificationCode({
                        variables: { businessId: payload.businessId, phone, recaptchaResponse },
                      });
                    }

                    if (payload.channel === 'phone') {
                      sendEmailVerificationCode({
                        variables: { businessId: payload.businessId, email, recaptchaResponse },
                      });
                    }

                    recaptchaRef.current.reset();
                  }
                }}
                disabled={
                  sendingEmailVerificationCode ||
                  sendingPhoneVerificationCode ||
                  (!payload.name && !name) ||
                  (payload.channel === 'email' && !phone) ||
                  (payload.channel === 'phone' && !email) ||
                  !password
                }
              >
                {sendingEmailVerificationCode || sendingPhoneVerificationCode ? (
                  <CircularProgress size={24} />
                ) : (
                  'Regístrate'
                )}
              </Button>

              <Box sx={{ textAlign: 'center' }}>
                <Link href="/" variant="body2">
                  ¿Ya tienes cuenta? Inicia sesión
                </Link>
              </Box>

              <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 5 }}>
                Powered by StudioM
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={verificationCodeOpen}
        maxWidth="xs"
        fullWidth
        onClose={() => setVerificationCodeOpen(false)}
      >
        <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
          <IconButton onClick={() => setVerificationCodeOpen(false)}>
            <Icon>close</Icon>
          </IconButton>
        </Box>

        <DialogTitle>
          {payload.channel === 'email' ? 'Verifica tu teléfono' : 'Verifica tu correo electrónico'}
        </DialogTitle>

        <DialogContent>
          <Typography>
            {payload.channel === 'email'
              ? 'Te enviamos un SMS con un código de verificación, para verificar tu cuenta ingrésalo aquí:'
              : 'Te enviamos un correo electrónico con un código de verificación, para verificar tu cuenta ingrésalo aquí:'}
          </Typography>

          <TextField
            margin="normal"
            required
            fullWidth
            label="Código de verificación"
            value={verificationCode || ''}
            onChangeCapture={(e) => setVerificationCode(e.target.value)}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />

          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() =>
              register({
                variables: {
                  invitationToken: token,
                  input: {
                    name,
                    email,
                    phone,
                    password,
                  },
                  verificationCode,
                },
              })
            }
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Regístrate'}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
