/* eslint-disable react/jsx-props-no-spreading */

import React, { Fragment, useState } from 'react';
import { func, string, bool, instanceOf, elementType } from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Divider,
  Icon,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Radio,
  ListItemText,
} from '@mui/material';

export default function Confirm({
  title,
  description,
  onConfirm,
  verb,
  buttonColor,
  icon,
  component: Component,
  children,
  alreadyConfirmed,
  closeOnCorner,
  options,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);

  return (
    <>
      <Component {...props} onClick={alreadyConfirmed ? onConfirm : () => setOpen(true)}>
        {children}
      </Component>

      <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
        {closeOnCorner && (
          <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
            <IconButton onClick={() => setOpen(false)}>
              <Icon>close</Icon>
            </IconButton>
          </Box>
        )}
        {title && <DialogTitle>{title}</DialogTitle>}
        {(description || options) && (
          <DialogContent>
            {description && <Typography sx={options ? { mb: 2 } : {}}>{description}</Typography>}
            {options && (
              <List dense>
                {options.map((option) => (
                  <Fragment key={option.id}>
                    <Divider />
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => setValue(option)}>
                        <ListItemIcon>
                          <Radio
                            edge="start"
                            checked={Boolean(value && value.id === option.id)}
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText primary={option.name} />
                      </ListItemButton>
                    </ListItem>
                  </Fragment>
                ))}
              </List>
            )}
          </DialogContent>
        )}

        <Divider />
        <DialogActions>
          {!closeOnCorner && (
            <Button variant="outlined" onClick={() => setOpen(false)} size="small">
              <Icon>cancel</Icon>
              <Box sx={{ ml: 1 }}>Cerrar</Box>
            </Button>
          )}
          <Button
            variant="contained"
            color={buttonColor}
            onClick={() => {
              setOpen(false);
              onConfirm(value);
            }}
            size="small"
          >
            {icon && <Icon>{icon}</Icon>}
            <Box sx={{ ml: icon ? 1 : 0 }}>{verb}</Box>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

Confirm.propTypes = {
  title: string,
  description: string,
  onConfirm: func.isRequired,
  verb: string,
  buttonColor: string,
  icon: string,
  component: elementType.isRequired,
  children: instanceOf(Object),
  alreadyConfirmed: bool,
  closeOnCorner: bool,
  options: instanceOf(Array),
};

Confirm.defaultProps = {
  title: null,
  description: null,
  verb: 'Confirmar',
  buttonColor: 'primary',
  icon: null,
  children: null,
  alreadyConfirmed: false,
  closeOnCorner: false,
  options: null,
};
