import React, { useState } from 'react';
import { instanceOf, func, number } from 'prop-types';
import {
  Box,
  Toolbar,
  IconButton,
  Icon,
  ListItemIcon,
  Container,
  Tooltip,
  Avatar,
  Menu,
  MenuItem,
  AppBar,
  Typography,
  Link,
} from '@mui/material';
import SignUpRequest from '../Auth/SignUpRequest';
import SignIn from '../Auth/SignIn';

export default function Layout({ authenticatedUser, children, handleUpdate, credits }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSignOut = () => {
    window.localStorage.setItem('authToken', '');
    handleUpdate();
  };

  return (
    <Box>
      <AppBar position="absolute" sx={{ backgroundColor: '#fff' }}>
        <Toolbar>
          <Box
            component="img"
            src={process.env.APPBAR_LOGO}
            alt={process.env.BUSINESS_NAME}
            sx={{ height: 42 }}
          />

          <Box sx={{ flex: 1 }} />

          {!authenticatedUser && (
            <>
              <Box sx={{ mr: 1 }}>
                <SignIn handleUpdate={handleUpdate} iconButtonOnMobile color="primary" />
              </Box>

              <SignUpRequest />
            </>
          )}

          {authenticatedUser && (
            <>
              <Typography
                variant="caption"
                color="textSecondary"
              >{`${credits} créditos`}</Typography>
              <Tooltip title="Tu cuenta">
                <IconButton
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  size="small"
                  sx={{ ml: 2 }}
                >
                  <Avatar
                    src={authenticatedUser.picture}
                    sx={(theme) => ({ backgroundColor: theme.palette.secondary.main })}
                  >
                    {authenticatedUser.name.slice(0, 1).toUpperCase()}
                  </Avatar>
                </IconButton>
              </Tooltip>
            </>
          )}

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            onClick={() => setAnchorEl(null)}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 40,
                  height: 40,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 20,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleSignOut}>
              <ListItemIcon>
                <Icon>logout</Icon>
              </ListItemIcon>
              Cerrar sesión
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          flexGrow: 1,
          minHeight: '100vh',
          overflow: 'auto',
          paddingBottom: '120px',
        }}
      >
        <Toolbar />

        <Box
          component="img"
          src={process.env.BANNER_BACKGROUND}
          sx={{
            width: '100%',
            height: '40vh',
            objectFit: 'cover',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            objectPosition: '50% 50%',
          }}
        />

        <Container maxWidth="lg" sx={{ mt: 8, mb: 16 }}>
          {children}
        </Container>
      </Box>

      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: '#fff !important',
          marginTop: '-120px',
          minHeight: 120,
          py: 2,
        })}
      >
        <Container
          maxWidth="lg"
          sx={{
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            component="img"
            src={process.env.FOOTER_LOGO}
            sx={{ width: 260, height: 100, objectFit: 'contain' }}
          />

          <Link
            href={process.env.PRIVACY_POLICY}
            target="_blank"
            sx={{ color: '#fff' }}
            variant="caption"
          >
            Aviso de privacidad
          </Link>
        </Container>
      </Box>
    </Box>
  );
}

Layout.propTypes = {
  authenticatedUser: instanceOf(Object),
  children: instanceOf(Array).isRequired,
  handleUpdate: func.isRequired,
  credits: number,
};

Layout.defaultProps = {
  authenticatedUser: null,
  credits: 0,
};
