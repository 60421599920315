/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import AdapterDateMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { SnackbarProvider } from 'notistack';

import Reserve from './Reserve/Reserve';
import SignUp from './Auth/SignUp';

const theme = createTheme({
  palette: {
    text: { primary: process.env.TEXT_COLOR },
    primary: {
      main: process.env.PRIMARY_COLOR,
    },
    secondary: {
      main: process.env.SECONDARY_COLOR,
    },
    background: {
      default: process.env.BACKGROUND_COLOR,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: 100,
        },
        outlined: {
          borderRadius: 100,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        rounded: {
          borderRadius: 12,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontWeight: 'bold',
        },
      },
    },
  },
});

const httpLink = createHttpLink({
  uri: `${process.env.API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('authToken');

  if (!token) {
    return { headers };
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default function App() {
  return (
    <ApolloProvider client={client}>
      <LocalizationProvider dateAdapter={AdapterDateMoment}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <CssBaseline />
            <Routes>
              <Route path="/sign-up" exact element={<SignUp />} />
              <Route path="/" exact element={<Reserve />} />
            </Routes>
          </SnackbarProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </ApolloProvider>
  );
}
