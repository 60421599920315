import React, { useMemo, useState } from 'react';
import moment from 'moment';
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
  Avatar,
  Grid,
  Stack,
  Box,
  CircularProgress,
  Icon,
  Button,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';

import Layout from '../Common/Layout';
import Session from './Session';

export default function Reserve() {
  const [selectedStudio, setSelectedStudio] = useState(null);
  const [done, setDone] = useState(false);

  const { data, refetch } = useQuery(
    gql`
      query GetData($businessId: ID!, $since: String!, $until: String!) {
        me {
          id
          name
          picture
          upcomingReservations {
            id
            session {
              id
            }
          }
          packs {
            startAt
            endAt
            availableCredits
          }
        }
        studiosByBusiness(businessId: $businessId) {
          id
          name
          logo
          picture
          address {
            street
            neighborhood
            city
            state
            zipcode
            country
          }
          business {
            workdays
          }
        }
        sessionsByBusiness(businessId: $businessId, since: $since, until: $until) {
          id
          room {
            id
            name
            studio {
              id
              name
            }
          }
          instructor {
            id
            name
            picture
          }
          discipline {
            id
            name
          }
          label
          startsAt
          duration
          status
          availableSeats
          seats
        }
      }
    `,
    {
      variables: {
        businessId: process.env.BUSINESS_ID,
        since: moment().startOf('day'),
        until: moment().add(7, 'days').endOf('day'),
      },
      notifyOnNetworkStatusChange: true,
      pollInterval: 30000,
    }
  );

  const moreSessionsToday = useMemo(
    () =>
      ((data || { sessionsByBusiness: [] }).sessionsByBusiness || []).some(
        (session) =>
          moment(session.startsAt).isBetween(moment().startOf('day'), moment().endOf('day')) &&
          moment(session.startsAt).add(session.duration, 'minutes').isAfter(moment()) &&
          session.status !== 'canceled' &&
          session.room.studio.id === (selectedStudio || { id: null }).id
      ),
    [data, selectedStudio]
  );

  return (
    <Layout
      authenticatedUser={data ? data.me : null}
      handleUpdate={() => refetch()}
      credits={
        data && data.me
          ? data.me.packs
              .filter((pack) => moment().isBetween(pack.startAt, pack.endAt))
              .reduce((total, pack) => total + pack.availableCredits, 0)
          : null
      }
    >
      <Typography
        variant="h4"
        sx={(theme) => ({
          textAlign: 'center',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          color: theme.palette.primary.main,
          mb: 1,
        })}
      >
        ¡Reserva tu clase!
      </Typography>

      <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center', mb: 4 }}>
        Reservar es muy fácil, sigue estos 3 sencillos pasos y asegura tu lugar. ¡Nos vemos pronto!
      </Typography>

      {/* steps */}
      {/* <Stepper activeStep={!!selectedStudio + done} alternativeLabel> */}
      <Stepper activeStep={2} alternativeLabel>
        <Step>
          <StepLabel sx={{ cursor: 'pointer' }} onClick={() => setSelectedStudio(null)}>
            Escoge una sucursal
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>Selecciona tu clase</StepLabel>
        </Step>
        <Step>
          <StepLabel>¡Listo!</StepLabel>
        </Step>
      </Stepper>

      {!data && (
        <Box sx={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress color="secondary" size={80} />
        </Box>
      )}

      {/* step 1: studio */}
      {!done && !selectedStudio && data && (
        <Grid
          container
          spacing={4}
          sx={{ mt: 4, mb: 4, justifyContent: 'center', alignItems: 'center' }}
        >
          {data.studiosByBusiness.map((studio) => (
            <Grid key={studio.id} item xs={12} md={6} lg={4}>
              <Card>
                <CardActionArea onClick={() => setSelectedStudio(studio)}>
                  <CardHeader
                    avatar={<Avatar src={studio.logo}>{studio.name}</Avatar>}
                    title={studio.name}
                    subheader={[
                      studio.address.street,
                      studio.address.neighborhood,
                      studio.address.city,
                    ].join(', ')}
                  />
                  <CardMedia
                    component="img"
                    height="194"
                    image={studio.picture}
                    alt={studio.name}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {/* step 2: session */}
      {!done && selectedStudio && data && (
        <Box sx={{ overflow: 'auto' }}>
          <Stack sx={{ mt: 6, mb: 4 }} direction="row" spacing={2}>
            {[0, 1, 2, 3, 4, 5, 6].map((offset) => {
              const date = moment().add(offset, 'days');

              const sessions = data.sessionsByBusiness
                .filter(
                  (session) =>
                    moment(session.startsAt).isBetween(
                      moment(date).startOf('day'),
                      moment(date).endOf('day')
                    ) &&
                    moment(session.startsAt).add(session.duration, 'minutes').isAfter(moment()) &&
                    session.status !== 'canceled' &&
                    session.room.studio.id === selectedStudio.id
                )
                .sort((a, b) => new Date(a.startsAt).getTime() - new Date(b.startsAt).getTime());

              if (!selectedStudio.business.workdays.includes(date.day()) && sessions.length === 0) {
                return null;
              }

              return (
                <Box sx={{ width: '100%' }} key={offset}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mb: 2,
                    }}
                  >
                    <Typography
                      sx={(theme) => ({
                        width: 40,
                        height: 40,
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.primary.main,
                        borderRadius: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        mb: 1,
                      })}
                    >
                      {date.format('DD')}
                    </Typography>
                    <Typography variant="button" color="textSecondary" sx={{ textAlign: 'center' }}>
                      {date.locale('es').format('ddd').replace('.', '')}
                    </Typography>
                  </Box>

                  <Stack spacing={2}>
                    {sessions.map((session) => (
                      <Session
                        key={session.id}
                        session={session}
                        handleUpdate={() => refetch()}
                        me={data.me}
                        reservation={
                          data.me
                            ? data.me.upcomingReservations.find(
                                (reservation) => reservation.session.id === session.id
                              )
                            : null
                        }
                        moreSessionsToday={moreSessionsToday}
                        handleDone={() => setDone(true)}
                      />
                    ))}

                    {sessions.length === 0 && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                          p: 4,
                        }}
                      >
                        <Typography variant="caption" color="textSecondary">
                          Sin clases
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                </Box>
              );
            })}
          </Stack>
        </Box>
      )}

      {/* step 3: done */}
      {done && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            mt: 4,
            flexDirection: 'column',
          }}
        >
          <Avatar
            sx={(theme) => ({
              backgroundColor: theme.palette.secondary.main,
              width: 130,
              height: 130,
            })}
          >
            <Icon sx={{ fontSize: 60 }}>event_available</Icon>
          </Avatar>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Listo, ¡nos vemos en tu clase!
          </Typography>

          <Button variant="outlined" size="large" sx={{ mt: 4 }} onClick={() => setDone(false)}>
            Reservar otra clase
          </Button>
        </Box>
      )}
    </Layout>
  );
}
