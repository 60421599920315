/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef } from 'react';
import {
  Avatar,
  Icon,
  Typography,
  Box,
  TextField,
  Button,
  CircularProgress,
  DialogContent,
  Dialog,
  Hidden,
  IconButton,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { func, bool } from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

export default function SignIn({ handleUpdate, iconButtonOnMobile, ...props }) {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const recaptchaRef = useRef();

  const [getAuthToken, { loading, error, data, client }] = useMutation(
    gql`
      mutation GetAuthToken($id: String!, $password: String!, $recaptchaResponse: String!) {
        authToken(id: $id, password: $password, recaptchaResponse: $recaptchaResponse)
      }
    `
  );

  useEffect(() => {
    if (data && data.authToken) {
      setOpen(false);
      window.localStorage.setItem('authToken', data.authToken);
      client.resetStore();
      handleUpdate();
    }
  }, [data, client, handleUpdate]);

  return (
    <>
      <Hidden {...(iconButtonOnMobile ? { mdUp: true } : { xsUp: true })}>
        <IconButton onClick={() => setOpen(true)} {...props}>
          <Icon>account_circle</Icon>
        </IconButton>
      </Hidden>

      <Hidden {...(iconButtonOnMobile ? { smDown: true } : {})}>
        <Button onClick={() => setOpen(true)} {...props}>
          <Icon>account_circle</Icon>
          <Box sx={{ ml: 1 }}>Ingresa</Box>
        </Button>
      </Hidden>

      <Dialog open={open} fullWidth maxWidth="xs" onClose={() => setOpen(false)}>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <Icon>lock_outlined</Icon>
            </Avatar>
            <Typography component="h1" variant="h5">
              Iniciar sesión
            </Typography>

            {error && (
              <Typography variant="body2" color="error" mt={4}>
                Verifique sus datos
              </Typography>
            )}

            <Box sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Correo electrónico"
                autoFocus
                autoComplete="email"
                value={id || ''}
                onChangeCapture={(e) => setId(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Contraseña"
                type="password"
                autoComplete="current-password"
                value={password || ''}
                onChangeCapture={(e) => setPassword(e.target.value)}
              />
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.RECAPTCHA_SITE_KEY}
              />
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={async () => {
                  const recaptchaResponse = await recaptchaRef.current.executeAsync();
                  if (recaptchaResponse) {
                    getAuthToken({ variables: { id, password, recaptchaResponse } });
                    recaptchaRef.current.reset();
                  }
                }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Iniciar sesión'}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

SignIn.propTypes = {
  handleUpdate: func.isRequired,
  iconButtonOnMobile: bool,
};

SignIn.defaultProps = {
  iconButtonOnMobile: false,
};
