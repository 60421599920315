import React, { useEffect, useState } from 'react';
import { instanceOf, func, bool } from 'prop-types';
import moment from 'moment';
import {
  Paper,
  Typography,
  ButtonBase,
  Dialog,
  DialogContent,
  IconButton,
  Box,
  Icon,
  Avatar,
  Divider,
  Grid,
  Fab,
  Button,
  CircularProgress,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import SignIn from '../Auth/SignIn';
import SignUpRequest from '../Auth/SignUpRequest';
import { translatePublicError } from '../../../utils/translateError';
import Confirm from '../../../app/components/Common/Confirm';

export default function Session({
  me,
  session,
  handleUpdate,
  reservation,
  moreSessionsToday,
  handleDone,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [selectedSeat, setSelectedSeat] = useState(null);

  const [createReservation, { loading: creating, error }] = useMutation(
    gql`
      mutation CreateReservation($input: ReservationInput!) {
        createReservation(input: $input)
      }
    `,
    { onError: handleUpdate }
  );

  const [cancelReservation, { loading: canceling }] = useMutation(
    gql`
      mutation CancelReservation($reservationId: ID!, $input: ReservationInput!) {
        updateReservation(reservationId: $reservationId, input: $input)
      }
    `,
    { onError: handleUpdate }
  );

  useEffect(() => {
    if (error) {
      if (error.message === 'create-reservation-duplicate') {
        setOpen(false);
      }

      enqueueSnackbar(translatePublicError(error.message), {
        variant: error.message === 'create-reservation-duplicate' ? 'success' : 'error',
      });
    }
  }, [enqueueSnackbar, error]);

  const instructor =
    session.instructor &&
    moment(session.startsAt)
      .subtract(moreSessionsToday ? 0 : 1, 'day')
      .startOf('day')
      .toDate() <= new Date()
      ? session.instructor
      : null;

  return (
    <>
      <Paper
        component={reservation ? 'div' : ButtonBase}
        key={session.id}
        sx={(theme) => ({
          p: 2,
          flexDirection: 'column',
          ...(reservation ? { backgroundColor: theme.palette.secondary.main } : {}),
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          minWidth: 160,
        })}
        onClick={() => {
          if (!reservation) {
            setOpen(true);
            setSelectedSeat(null);
          }
        }}
      >
        <Typography
          variant="h6"
          sx={(theme) => ({ textAlign: 'center', color: theme.palette.primary.main })}
        >
          {moment(session.startsAt).format('hh:mm a')}
        </Typography>
        <Typography variant="caption" color="textSecondary" sx={{ textAlign: 'center' }}>
          {`${session.duration} mins`}
        </Typography>

        {session.label && (
          <Typography variant="body1" sx={{ textAlign: 'center' }} color="secondary">
            {session.label}
          </Typography>
        )}

        <Typography variant="body2" sx={{ textAlign: 'center' }}>
          {instructor ? instructor.name : ''}
        </Typography>

        {session.availableSeats.length <= 5 && (
          <Typography variant="caption" color="error" sx={{ textAlign: 'center', mt: 2 }}>
            {session.availableSeats.length === 1
              ? '¡Queda 1 lugar!'
              : `¡Quedan ${session.availableSeats.length} lugares!`}
          </Typography>
        )}

        {reservation && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" color="primary" sx={{ textAlign: 'center' }}>
              ¡Ya reservaste!
            </Typography>

            <Confirm
              component={Button}
              title="¿Estás seguro de que quieres cancelar tu reservación?"
              description="Perderás tu lugar en esta clase. Si faltan menos de 24 horas para el inicio de la clase obtendrás de vuelta tu crédito utilizado para esta reservación."
              verb="Cancelar"
              icon="event_busy"
              onConfirm={() => {
                cancelReservation({
                  variables: {
                    reservationId: reservation.id,
                    input: {
                      status: 'canceled',
                    },
                  },
                  update: () => {
                    handleUpdate();
                  },
                });
              }}
              disabled={canceling}
              closeOnCorner
              variant="contained"
              size="small"
            >
              <>
                <Icon>event_busy</Icon>
                <Box sx={{ ml: 1 }}>Cancelar</Box>
              </>
            </Confirm>
          </Box>
        )}
      </Paper>

      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
        <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
          <IconButton onClick={() => setOpen(false)}>
            <Icon>close</Icon>
          </IconButton>
        </Box>
        {!selectedSeat && (
          <>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h4" color="secondary">
                {session.discipline.name}
              </Typography>
              <Typography variant="button">
                {moment(session.startsAt)
                  .locale('es')
                  .format('dddd, DD MMM hh:mm a')
                  .replace('.', '')}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {`${session.duration} mins`}
              </Typography>
              <Avatar
                sx={{ mt: 2, width: 160, height: 160 }}
                src={instructor ? instructor.picture : null}
              >
                {instructor ? (
                  instructor.name.slice(0, 1).toUpperCase()
                ) : (
                  <Icon>question_mark</Icon>
                )}
              </Avatar>
              <Typography variant="caption" color="textSecondary" sx={{ mt: 2 }}>
                {`Instructor: ${instructor ? instructor.name : 'por definir'}`}
              </Typography>
            </DialogContent>
            <Divider />
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Selecciona tu lugar
              </Typography>

              <Grid container spacing={2} sx={{ justifyContent: 'center', mt: 1, mb: 1 }}>
                {[...new Array(session.seats)]
                  .map((el, idx) => idx + 1)
                  .map((seat) => (
                    <Grid item key={seat}>
                      <Fab
                        size="small"
                        disabled={!session.availableSeats.includes(seat)}
                        color="secondary"
                        onClick={() => setSelectedSeat(seat)}
                        sx={(theme) => ({ color: theme.palette.primary.main, boxShadow: 0 })}
                      >
                        {seat}
                      </Fab>
                    </Grid>
                  ))}
              </Grid>
            </DialogContent>
          </>
        )}

        {selectedSeat && !me && (
          <DialogContent>
            <Typography variant="h6" sx={{ textAlign: 'center', mt: 2 }}>
              ¡Estás a un paso de reservar tu clase!
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center', mt: 1, mb: 4 }}>
              Entra a tu cuenta o regístrate para terminar tu reservación.
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <SignIn handleUpdate={handleUpdate} variant="contained" size="large" sx={{ mr: 2 }} />
              <SignUpRequest variant="normal" />
            </Box>
          </DialogContent>
        )}

        {selectedSeat && me && (
          <DialogContent>
            <Typography variant="h6" sx={{ textAlign: 'center', mt: 2 }}>
              ¡Confirma tu reservación!
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center', mt: 1, mb: 4 }}>
              Al hacer clic en confirmar utilizarás uno de tus créditos disponibles y asegurarás tu
              lugar en esta clase.
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button
                variant="contained"
                size="large"
                onClick={() =>
                  createReservation({
                    variables: {
                      input: {
                        user: me.id,
                        session: session.id,
                        status: 'upcoming',
                        seat: selectedSeat,
                      },
                    },
                    update: () => {
                      setOpen(false);
                      handleUpdate();
                      handleDone();
                    },
                  })
                }
                disabled={creating}
              >
                {creating && <CircularProgress size={24} sx={{ mr: 1 }} />}
                Confirmar reservación
              </Button>
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}

Session.propTypes = {
  session: instanceOf(Object).isRequired,
  handleUpdate: func.isRequired,
  me: instanceOf(Object),
  reservation: instanceOf(Object),
  moreSessionsToday: bool,
  handleDone: func.isRequired,
};

Session.defaultProps = {
  reservation: null,
  me: null,
  moreSessionsToday: true,
};
