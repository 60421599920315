/* eslint-disable react/jsx-props-no-spreading */

import { func } from 'prop-types';
import React, { forwardRef } from 'react';
import NumberFormat from 'react-number-format';

export const PhoneNumberInput = forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      isNumericString
      type="tel"
      format="## #### ####"
      mask="_"
    />
  );
});

PhoneNumberInput.propTypes = {
  onChange: func.isRequired,
};

export default { PhoneNumberInput };
