import { gql, useMutation } from '@apollo/client';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Icon,
  IconButton,
  TextField,
  Typography,
  Link,
} from '@mui/material';
import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { translatePublicError } from '../../../utils/translateError';

export default function SignUpRequest({ ...props }) {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const recaptchaRef = useRef();

  const [registerByEmail, { loading, data, error }] = useMutation(
    gql`
      mutation SelfRegistrationEmail(
        $businessId: ID!
        $email: String!
        $recaptchaResponse: String!
      ) {
        selfRegistrationEmail(
          businessId: $businessId
          email: $email
          recaptchaResponse: $recaptchaResponse
        )
      }
    `
  );

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Button variant="contained" onClick={() => setOpen(true)} {...props}>
        Regístrate
      </Button>

      <Dialog open={open} fullWidth maxWidth="xs" onClose={() => setOpen(false)}>
        <Box sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}>
          <IconButton onClick={() => setOpen(false)}>
            <Icon>close</Icon>
          </IconButton>
        </Box>

        {!data && (
          <DialogContent>
            {error && (
              <Typography variant="body2" color="error" mb={4} align="center">
                {translatePublicError(error.message)}
              </Typography>
            )}

            <TextField
              margin="normal"
              required
              fullWidth
              label="Correo electrónico"
              value={email || ''}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
            />

            <Box sx={{ mb: 2, textAlign: 'center' }}>
              <Typography variant="caption" color="textSecondary">
                {'Al registrarte aceptas los '}
                <Link href={process.env.LEGAL} target="_blank">
                  términos de servicio
                </Link>
                .
              </Typography>
            </Box>

            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.RECAPTCHA_SITE_KEY}
            />

            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={async () => {
                const recaptchaResponse = await recaptchaRef.current.executeAsync();
                if (recaptchaResponse) {
                  registerByEmail({
                    variables: { businessId: process.env.BUSINESS_ID, email, recaptchaResponse },
                  });
                  recaptchaRef.current.reset();
                }
              }}
              disabled={loading}
            >
              {loading && <CircularProgress size={24} sx={{ mr: 2 }} />}
              Regístrate
            </Button>
          </DialogContent>
        )}

        {data && (
          <DialogContent>
            <Avatar
              sx={(theme) => ({
                width: 100,
                height: 100,
                backgroundColor: theme.palette.secondary.main,
                margin: '0 auto',
              })}
            >
              <Icon sx={{ fontSize: 50 }}>mark_email_read</Icon>
            </Avatar>

            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <Typography>
                Revisa tu correo electrónico para continuar con tu proceso de registro.
              </Typography>
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}
