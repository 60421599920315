const commonErrors = {
  'already-exists': 'Ya existe un usuario con estos datos.',
  'invalid-email': 'Dirección de correo electrónico inválida.',
  'invalid-phone': 'Número de teléfono inválido.',
  'invalid-code': 'Código de verificación inválido.',
};

export const translateError = (status) =>
  ({
    ...commonErrors,
    'create-reservation-no-credits': 'El cliente no tiene créditos disponibles.',
    'create-reservation-exceeded-max-reservations': 'El cliente ya tiene 3 reservaciones activas.',
    'create-reservation-duplicate': 'El cliente ya tiene una reservación activa en esta clase.',
    'create-reservation-duplicate-seat':
      'Otro cliente ya tiene una reservación en el mismo lugar, ¡intenta con otro!',
    'create-reservation-same-day': 'El cliente ya tiene una reservación activa en este día.',
  }[status] || 'Ocurrió un error inesperado.');

export const translatePublicError = (status) =>
  ({
    ...commonErrors,
    'create-reservation-no-credits': 'No tienes créditos disponibles.',
    'create-reservation-exceeded-max-reservations': 'Ya tienes 3 reservaciones activas.',
    'create-reservation-duplicate': 'Ya tienes una reservación activa en esta clase.',
    'create-reservation-duplicate-seat':
      'Lo sentimos, otro cliente acaba de reservar el lugar que seleccionaste, ¡intenta con otro asiento!.',
    'create-reservation-same-day': 'Ya tienes una reservación activa en este día.',
  }[status] || 'Ocurrió un error inesperado.');

export default { translateError, translatePublicError };
